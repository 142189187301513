.pl-pricingTable-title{
    text-align: center;
    color: #999fb5;
    font-size: 3em;
    font-size: 300%;
    letter-spacing: 0.04em;
    padding-top: 30px;
}
.pl-pricingTable-subtitle{
    text-align: center;
    color: #b4bdc6;
    font-size: 1.8em;
    letter-spacing: 0.04em;
}
.pl-card-body{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}
.pl-body{
    background: #3C385C;
    background: -webkit-linear-gradient(to right, #3C385C, #6c6896);
    background: linear-gradient(to right, #3C385C, #6c6896);
    width: 100%;
    font-family: 'Open Sans', sans-serif;
    border-radius: 5px;
    min-height: 80vh;
}
.pl-outer-container{
    /* margin: 100px auto 0 auto; */
    width: 100%;
    /* max-width: 500px; */
    /* margin-top: 10%; */
    padding-bottom: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: auto;
  }
  
  .pl-container{
    margin: 5px !important;
    width: 300px;
    height: 400px;
    border-radius: 10px;
    /* float: left; */
    position: relative;
    top: 0;
    z-index: 1;
    box-shadow: 0 5px 10px 0px #3C385C;
    cursor: pointer;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .pl-container1{
    background: #5967C3;
    background: -webkit-linear-gradient(left top, #5967C3, #83cedc);
    background: linear-gradient(to bottom right, #5967C3, #83cedc);
    margin-left: -50px;
  }
  
  .pl-container2{
    background: #ffaec2;
    background: -webkit-linear-gradient(left top, #ffaec2, #7e4aaf);
    background: linear-gradient(to bottom right, #ffaec2, #7e4aaf);
    z-index: 2;
  }
  
  .pl-container3{
    background: #CA619C;
    background: -webkit-linear-gradient(left top, #CA619C, #DBC084);
    background: linear-gradient(to bottom right, #CA619C, #DBC084);
    margin-left: -50px;
  }
  
  .pl-active{
    z-index: 3;
    top: -25px;
    /* height: 320px; */
  }
  
  .pl-title{
    color: #FFF;
    font-size: 1.2rem;
    text-align: center;
    margin-top: 15px;
  }
  
  .pl-title::after{
    content: '';
    display: block;
    height: 3.5px;
    width: 13px;
    background-color: #FFF;
    border-radius: 15px;
    margin: 5px auto 0 auto;
  }
  
  .pl-main_number{
    position: absolute;
    top: 35%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0; 
    right: 0;
    text-align: center;
    font-size: 270%;
    color: #FFFFFF;
    opacity: 0.2;
    font-weight: 800;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  /* .pl-main_number::before{
    content: '$';
    font-size: 1.8rem;
    position: absolute;
    top: -10%;
    left: 20px;
  } */
  
  .pl-active .main_number{
    top: 45%;
  }
  
  .pl-container a{
    text-decoration: none;
    color: #3C385C;
    font-weight: 800;
    font-size: 0.7rem;
    background-color: #FFF;
    width: 170px;
    line-height: 40px;
    display: block;
    border-radius: 30px;
    position: absolute;
    bottom: 20px;
    left: 0; right: 0;
    margin: 0 auto;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .pl-container a span{
    opacity: 0.6;
  }
  
  .active a span{
    opacity: 1;
  }
  
  .pl-container_text{
    position: absolute;
    top: 66%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    left: 0; right: 0;
    text-align: center;
    color: #FFFFFF;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;
  }
  
  .active .pl-container_text{
    top: 65%;
  }
  
  .pl-container_text span{
    display: block;
  }
  
  .pl-container_text1{
    font-size: 0.9rem;
  }
  
  .pl-container_text2{
    font-size: 2.5rem;
  }
  /* @media only screen and (max-width: 48rem)
  {
    .active{
        z-index: 3;
        top: 0px;
        height: 320px;
    }
    .pl-card-body{
        flex-direction: column;
    }
    .pl-container{
        margin: 5px;
    }
    .pl-container2{
        margin-left: 0px;
    }
    .pl-container3{
        margin-left: 0px;
    }
  } */