.dmodal{
    background:rgba(255,255,255,0.15);
    backdrop-filter: blur(1px);
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.dmodal > .modal-dialog{
    all: unset;
    width: 60%;
    /* height: 60%; */
}
.dmodal > .modal-dialog > .modal-content{
    height: 100%;
}